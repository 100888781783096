@import "assets/scss/1-variables/app";

.dashboards {
	&-list {
		&-container {
			max-height: 40vh;
			overflow: scroll;
		}

		&-item {
			background-color: unset;
			&.list-group-item-action:hover,
			&.list-group-item-action:focus {
				color: $primary; 
				background-color: unset;
			}

			&.list-group-item.active {
				color: $primary;
				background-color: transparent;
				border-bottom-color: $primary;
				border-top-color: $primary !important;
				
			}
		}
	}

	&-tag {
		&-remove {
			float: right;
			z-index: 9;

			&:hover {
				font-weight: 800;
			}
		}
	}
}