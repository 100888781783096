@import "assets/scss/1-variables/app";

.modal {
	&-list {
		&-item {
			background-color: unset;
			&.list-group-item-action:hover,
			&.list-group-item-action:focus {
				color: $primary; 
				background-color: unset;
			}

			&.list-group-item.active {
				color: $primary;
				background-color: transparent;
				border-bottom-color: $primary;
				border-top-color: $primary !important;
				
			}
		}
	}
}