@import "assets/scss/1-variables/app";

$card-border-radius: 10px;
$thumbnail-min-width: 234px;
$thumbnail-min-height: 140px;

.project {
	&-header {
		&-filter {
			text-decoration-thickness: 3px !important;
		}
	}


	&-card {
		min-width: $thumbnail-min-width; //238px;
		max-width: $thumbnail-min-width; //238px;
		border: 1px solid;
		border-radius: $card-border-radius;

		&-col {
			max-width: 280px;
		}

		&-dropdown {
		position: absolute;
		z-index: 99;
		}
		
		&-overlay {
			position: absolute;
			top: 105px;
			vertical-align: middle;
			width: 100%;
			padding: 0px 10px;

			& .duration {
				float: right;
				background: rgba(0, 0, 0, 0.54);
				opacity: 1;
				padding: 1px 5px;
				border-radius: 5px;
			}

			& .icons {
				float: left;
				padding: 3px 2px 0px;
				margin: 0;
			}
		}

		&-thumbnail {
			width: $thumbnail-min-width;
			height: $thumbnail-min-height;
			background: $black;
			border-top-left-radius: $card-border-radius;
			border-top-right-radius: $card-border-radius;

			&.loader svg {
				width: $thumbnail-min-width;
				height: 100px;
    			// min-width: 80px;
    			    min-height: 80%;
			}
			
			& svg {
				width: $thumbnail-min-width;
				height: $thumbnail-min-height;
    			min-width: 80px;
    			min-height: 100%;
			}

			&.upload-progress {

			}
		}

		&-header {
			border-bottom-left-radius: $card-border-radius;
			border-bottom-right-radius: $card-border-radius;
		}

		&.upload {
			border-style: dashed;
			background: transparent;

			&.dragover {
				border-color: $success;
				& svg, p {
					color: $success;
				}
			}

			&:hover {
				border-color: $primary;
				& svg, p {
					color: $primary;
					text-decoration: underline;
					cursor: pointer;
				}
			}
		}

		&-upload {
			border-top-left-radius: $card-border-radius;
			border-top-right-radius: $card-border-radius;
			
			& svg {
				height: 43px;
    			min-width: 80px;
    			min-height: 100%;
			}
		}
	}	

}