@import "assets/scss/1-variables/app";

.icon {
	&-gripper {
		&:hover {
			cursor: move;
			cursor: grab;
			cursor: -moz-grab;
			cursor: -webkit-grab;
			color: $primary;
		}
	}
}